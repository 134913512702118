/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { getAccessPermissions } from '../../../../utils/AccessPermissionsUtils';
import {
    Layout,
    Menu,
    MenuProps,
    Button,
    Space,
    Divider,
    Row,
    Image,
} from 'antd';
import {
    BusinessCenter,
    People,
    EventAvailable,
    WatchLater,
    SupervisorAccount,
    CodeOutlined,
    DescriptionOutlined,
    SettingsOutlined,
    Payment,
    Person,
    Dashboard,
    Settings,
    ReceiptOutlined,
    MoneyOutlined
} from '@material-ui/icons';
import {
    FileDoneOutlined, PropertySafetyOutlined
} from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import ArkLogo from '../../../../assets/img/ark-logo.jpg'
import Ark from '../../../../assets/img/ark.jpg'
import './SideMenuView.less';
const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    theme?: 'light' | 'dark',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        theme,
    } as MenuItem;
}

const ark_logo = { marginTop: 20 }
const ark = { marginBottom: 20 }


const SideMenuView = () => {
    const location = useLocation();
    const history = useHistory();
    const [currentPath, setCurrentPath] = useState(location.pathname);

    const onClick: MenuProps['onClick'] = e => {
        setCurrentPath(e.key);
        history.push(e.key);
    };

    const employee = getAccessPermissions('/employee');
    const attendance = getAccessPermissions('/attendance');
    const leave = getAccessPermissions('/leave');
    const overtime = getAccessPermissions('/overtime');
    const customers = getAccessPermissions('/customers');
    const projects = getAccessPermissions('/projects');
    const reports = getAccessPermissions('/reports');
    const assets = getAccessPermissions('/assets');
    const billables = getAccessPermissions('/billables');
    const receivables = getAccessPermissions('/receivables');
    const permissions = getAccessPermissions('/accessibility');
    const loan = getAccessPermissions('/loan');
    const user_management = getAccessPermissions('/user-management');
    const bank_management = getAccessPermissions('/banks');
    const company_settings = getAccessPermissions('/company-settings');

    const items: MenuItem[] = [
        getItem('DASHBOARD', '/dashboard', <Dashboard className='menu-item-class' />),
        employee.access ? employee.access.read ? getItem('EMPLOYEES', '/employees', <People className='menu-item-class' />) : null : null,
        attendance.access ? attendance.access.read ? getItem('ATTENDANCE', '/attendance', <EventAvailable className='menu-item-class' />) : null : null,
        leave.access ? leave.access.read ? getItem('LEAVE', '/leave', <BusinessCenter className='menu-item-class' />) : null : null,
        overtime.access ? overtime.access.read ? getItem('OVERTIME', '/overtime', <WatchLater className='menu-item-class' />) : null : null,
        loan.access ? loan.access.read ? getItem('LOAN', '/loan', <Payment className='menu-item-class' />) : null : null,
        customers.access ? customers.access.read ? getItem('CUSTOMERS', '/customers', <SupervisorAccount className='menu-item-class' />) : null : null,
        projects.access ? projects.access.read ? getItem('PROJECTS', '/projects', <CodeOutlined className='menu-item-class' />) : null : null,
        billables.access ? billables.access.read ? getItem('BILLABLES', '/billables', <ReceiptOutlined className='menu-item-class' />) : null : null,
        receivables.access ? receivables.access.read ? getItem('RECEIVABLES', '/receivables', <FileDoneOutlined className='menu-item-class' />) : null : null,
        reports.access ? reports.access.read ? getItem('REPORTS', '/reports', <DescriptionOutlined className='menu-item-class' />) : null : null,
        assets.access ? assets.access.read ? getItem('ASSETS', '/assets', <PropertySafetyOutlined className='menu-item-class' />) : null : null,
        permissions.access ? permissions.access.read ? getItem('ACCESSIBILITY', '/accessibility', <SettingsOutlined className='menu-item-class' />) : null : null,
        user_management.access ? user_management.access.read ? getItem('USER', '/user-management', <Person className='menu-item-class' />) : null : null,
        bank_management.access ? bank_management.access.read ? getItem('BANK ACCOUNT', '/banks', <MoneyOutlined className='menu-item-class' />) : null : null,
        company_settings.access ? company_settings.access.read ? getItem('COMPANY SETTINGS', '/company-settings', <Settings className='menu-item-class' />) : null : null,
    ];

    useEffect(() => {
        const path = `/${location.pathname.split('/')[1]}`;
        setCurrentPath(path === '/employee' ? '/employees' : path);
    }, [location.pathname]);

    return (
        <Sider
            width={250}
            theme='light'
            style={{
                overflow: 'auto auto',
                minHeight: '100%',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
            }}
        >
            <Space
                direction="vertical"
                className='dashboard-menu'
            >
                <Row justify='center' style={ark_logo}>
                    <Image
                        preview={false}
                        width={60}
                        src={ArkLogo}
                    />
                </Row>
                <Row justify='center' style={ark}>
                    <Image
                        preview={false}
                        width={120}
                        src={Ark}
                    />
                </Row>
                <Menu
                    onClick={onClick}
                    mode='inline'
                    selectedKeys={[currentPath]}
                    defaultSelectedKeys={[currentPath]}
                    items={items}
                    theme='dark'
                    className='sidebar'
                />
            </Space>
        </Sider>
    );
}

export default SideMenuView