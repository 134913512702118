import TextFormatUtils from "./TextFormatUtils";

export const getAccessPermissions = (value: any) => {
    let module = value === '/employee' ? 'Employees' :
        value === '/user-management' ? 'User Management' :
            value === '/company-settings' ? 'Company Settings' : value.split('/').pop();
    value = module.charAt(0).toUpperCase() + module.slice(1);
    const user = JSON.parse(localStorage.getItem('user') as any);
    if (user && user.type === 'admin' && !user.role_id) {
        return {
            access: {
                read: true,
                write: true,
                delete: true,
                access_sensitive_data: true
            }
        }
    }
    function getRolePermissions(module: any) {
        return module.module_name === value;
    }
    if (user && user.role_id && user.role_id.permissions) {
        const [result] = user.role_id.permissions.filter(getRolePermissions);
        return {
            access: result && result.access ?
                result.access :
                {
                    read: false,
                    write: false,
                    delete: false,
                    access_sensitive_data: false
                }
        };
    }
    return {
        access: {
            read: false,
            write: false,
            delete: false,
            access_sensitive_data: false
        }
    };
}
