
/**
 * App configuration
 */
class APPConfig {
  /**
   * api url endpoint
   * sample: http://localhost:3000/api
   */
  static API_URL: string = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'https://gp-api.ark.ph/api';
  // static API_URL:string ='http://localhost:3001/api';
  // static API_URL:string = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'http://localhost:3000/api';
  static DEFAULT_TIMEZONE: string = process.env.REACT_APP_DEFAULT_TIMEZONE ? process.env.REACT_APP_DEFAULT_TIMEZONE : 'America/New_York';
  static SOCKET_URL: string = process.env.REACT_APP_API_SOCKET_URL ? process.env.REACT_APP_API_SOCKET_URL : 'https://gp-notif.ark.ph/';
}

export default APPConfig;