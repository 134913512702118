import axios from "axios";
import APPConfig from "../../config";
import APITokens from './APITokens';

class APIService {
  protected baseURL: string;

  constructor(url: string = "") {
    //set the default api url properly
    this.baseURL = url && url.length > 0 ? url : APPConfig.API_URL + "/api/admins";

    let refreshingToken: any = null;

    // Add a request interceptor
    axios.interceptors.request.use(async (config) => {
      //get the token
      const token = APITokens.getLocalTokens().access_token;

      if (token && token.length > 0 && token !== "undefined") {
        config.headers.Authorization = "Bearer " + token;
      }

      config.headers["Access-Control-Allow-Origin"] = "*";
      config.headers["Strict-Transport-Security"] = "max-age=31536000; includeSubDomains";
      config.headers["X-Frame-Options"] = 'DENY';
      return config;
    });

    // Add a response interceptor
    axios.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with the response data
        return response;
      },
      async (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const originalRequest = error?.config;

        // Access Token was expired
        if (error?.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
            // refreshingToken - used for calling refresh token once if there's multiple api calls of 401
            refreshingToken = refreshingToken ? refreshingToken : APITokens.refreshToken(this.baseURL);
            const newAccessToken = await refreshingToken;
            refreshingToken = null;
            
            //set the new access token
            localStorage.setItem("access_token", newAccessToken);
            if (newAccessToken) {
              axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
            }

            //call again the previous method
            return axios(originalRequest);

          } catch (_error: any) {
            if (_error?.response && _error?.response?.data) {
              return Promise.reject(_error?.response?.data);
            }
            return Promise.reject(_error);
          }
        }

        // Access Token was blocklisted
        if (error?.response?.status === 403) {
          localStorage.clear();
          window.location.replace('/login');
          return Promise.reject(error);
        }
        
        return Promise.reject(error);
      }
    );
  }


  buildURL = (method: string): string => {
    return this.baseURL + "/" + method;
  };

  get = async (method: string) => {
    return await axios.get(this.buildURL(method));
  };

  getWithRequestBody = async (data: any, method: string) => {
    return await axios.get(this.buildURL(method), data);
  };

  postBlob = (data: any, method: string, filename: string) => {
    axios({
      url: this.buildURL(method), //your url
      method: "post",
      responseType: "blob", // important,
      data: data,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  post = async (data: any, method: string) => {
    try {
      return await axios.post(this.buildURL(method), data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  postWithoutRequestBody = async (method: string) => {
    try {
      return await axios.post(this.buildURL(method));
    } catch (error) {
      return Promise.reject(error);
    }
  };

  put = async (data: any, method: string) => {
    return await axios.put(this.buildURL(method), data);
  };

  putWithoutRequestBody = async (method: string) => {
    return await axios.put(this.buildURL(method));
  };

  patch = async (data: any, method: string) => {
    return await axios.patch(this.buildURL(method), data);
  };

  patchWithoutRequestBody = async (method: string) => {
    return await axios.patch(this.buildURL(method));
  };

  remove = async (method: string) => {
    return await axios.delete(this.buildURL(method));
  };

  removeWithRequesthBody = async (method: string, data?: any) => {
    const config: any = {
      data,
      method: "delete",
      url: this.buildURL(method),
    };
    return await axios(config);
  };
}

export default APIService;
